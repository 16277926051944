.contact-container {
    text-align: center;
    align-items:center;
    padding-bottom: 4rem;
}

.contact-container h2 {
    margin: 10px;
    text-align: center;
}

.form-container {
    border-radius: 5px;
    margin: 10px;
}

.form-container input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 15px 0px;
}

.form-container textarea {
    width: 100%;
    height: 30vh;
    padding: 12px 16px;
    border: 1px solid #ccc; 
    border-radius: 4px;
    box-sizing: border-box; 
    margin-top: 6px; 
    margin-bottom: 16px;
    resize: vertical;
    overflow: auto;
}

.form-container button {
    margin-top: 20px;
    display: block;
    align-self: center;    
}

