@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');

body {
    font-family: 'Nunito Sans', sans-serif;
}

header{
    position: relative;
    background-color: #bdc3bfff;
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    color: whitesmoke;
    padding: 2px;
}

header h1 {
    padding-left: 20px;
}

.nav {
    display: flex;
    justify-content: flex-end;
    margin-left: 5%;
}

.nav-item a{
    color: whitesmoke;
    text-size-adjust:inherit;
}

body {
    background-color: #fffef3ff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.about-me {
    width: 100%;
    margin: 20px auto;
    padding-bottom: 3rem;
}

.about-me h1 {
    text-align: center;
}

.about-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 70%;
}

.profile {
    width: 20%;
    padding: 2px;
}

.bio {
    width: 80%;
    display: inline-block;
    padding: 5px;
}

footer {
    background-color: #bdc3bfff;
    color: whitesmoke;
    min-width: 100%;
    position: absolute;
    height: 10vh;
    padding: 8px;
    margin-bottom: auto;
}

@media screen and (max-width: 600px) {

.about-div {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.profile {
    width: 50%;
    padding: 2px;
    display: block;
    margin: auto;
}

.bio {
    display: block;
    padding: 5px;
    margin: auto;
}

.footer {
    position: relative;
}

}

