.skillsContainer {
    width: 70%;
    display: block;
    justify-content: center;
    padding: 10px;
    align-self: center;
    margin: auto;
}

.skillsContainer div{
    display: row;
    width: 70%;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;

}

.skillsList {
    list-style-type: none;
}