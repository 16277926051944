.portfolio {
    align-items:center;
    width: 100%;
    margin: auto;
    position: relative;
  min-height: 100vh;
}

.portfolio h2 {
    padding-top:20px;
    text-align: center;
    font-size: 32px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.projectList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    height: auto;
    margin: 10px;
    padding-bottom: 6rem;
}

.project {
    border-radius: 15px;
    border-style: ridge;
    width: 40%;
    height: auto;
    margin: 10px;
    text-align: center;
  }

.project img {
width: 95%;
border-radius: 10px;
padding: 1%;
}

.button-container {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
}

@media screen and (max-width: 600px) {
    .projectList {
        display: block;
        width: 100%;
        height: auto;
    }

    .project {
        width: 90%;
        height: auto;
        margin: 20px;
        text-align: center;
      }

}